@import '../../scss/components_base';

.container {
  // position: relative;
  min-height: 100vh;

  @include md {
    padding-bottom: 0;
  }
}

.copyContainer {
  @include gutter;
  @include body-large();
  padding-top: rem(100);
  position: relative;
}
.title {
  @include h1-secondary;
  padding-top: rem(100);
  padding-bottom: rem(300);
  text-align: center;
  letter-spacing: unset;
  line-height: normal;
  filter: blur(5px);
}
